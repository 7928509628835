//
//
//
//
//
//
//
//
//
//

import { addBusinessDays, format } from 'date-fns';
import { fi } from 'date-fns/locale';

export default {
    data() {
        return {
            product: null,
            deliveryTooltip: `
                <div class="space-y-4 text-left p-2">
                    <p>87% tilauksista on perillä 1-3 arkipäivässä lähetyksestä.</p>
                    <p class="font-bold">Toimitustapojen arvioidut toimitusajat arkipäivissä:</p>
                    <ul role="list">
                    <li>Posti Pikkupaketti: 1-5pv</li>
                    <li>Matkahuolto paketti: 1-2pv</li>
                    <li>Posti paketti: 1-2pv</li>
                    </ul>
                </div>
                `,
        };
    },
    computed: {
        estimatedShipping() {
            const fromDate = addBusinessDays(new Date(), 1);
            const from = format(fromDate, 'cccccc d MMM', { locale: fi });
            const to = format(addBusinessDays(fromDate, 2), 'cccccc d MMM', { locale: fi });
            return `${from} - ${to}`;
        },
    },
};
