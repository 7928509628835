//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';
import throttle from 'lodash.throttle';

export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
    },
    props: ['product', 'labels'],
    fetch() {
        this.pimage = this.product.small_image.url;
    },
    data() {
        return {
            pimage: null,
            active: 0,
            key: 1,
            zoomScale: 1,
            zoomX: 0,
            zoomY: 0,
            throttleTime: 50,
            mouseover: false,
            start: {},
            transformStyles: false,
            touchdevice: false,
        };
    },
    fetchKey() {
        return `image-gallery-${this.product.sku}`;
    },
    mounted() {
        const that = this;
        this.$root.$on('candidateProduct', function ({ product }) {
            that.active = 0;
            that.pimage = product.image.url;
            that.key += 1;
        });
    },
    computed: {
        handleThrottled() {
            return throttle(this.setZoom, this.throttleTime, { trailing: true });
        },
        activeLength() {
            return this.mediaGallery.length ? this.mediaGallery.length : 0;
        },
        handleThrottledMove() {
            return throttle(this.handleTouchMove, 5);
        },
        mediaGallery() {
            return this.prepareGallery(this.product.media_gallery);
        },
    },
    methods: {
        styleStringToObject(styleString) {
            return styleString.split(';').reduce((styleObject, style) => {
                if (style.trim()) {
                    const [property, ...valueParts] = style.split(':');
                    const value = valueParts.join(':').trim(); // Rejoin in case value contains colons
                    if (property && value) {
                        const camelCaseProperty = property.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
                        styleObject[camelCaseProperty] = value;
                    }
                }
                return styleObject;
            }, {});
        },
        handleMouseover(event) {
            this.mouseover = true;
            this.handleThrottled(event);
        },
        setZoom(event) {
            if (!this.mouseover || this.touchdevice) {
                return;
            }

            this.zoomScale = 3;
            let scalechange = this.zoomScale - 1;

            this.zoomX = -(event.offsetX * scalechange) / this.zoomScale;
            this.zoomY = -(event.offsetY * scalechange) / this.zoomScale;
            this.transformStyles = `transform:scale(${this.zoomScale}) translate(${this.zoomX}px, ${this.zoomY}px)`;
        },
        distance(event) {
            return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);
        },
        handleTouchStart(event) {
            this.touchdevice = true;
            if (event.touches.length === 2) {
                event.preventDefault();

                this.start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                this.start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.start.distance = this.distance(event);
                this.$refs.slider?.glide?.update({ touchAngle: 0 });
            }
        },
        handleTouchMove(event) {
            if (event.touches.length === 2) {
                event.preventDefault();

                // Safari provides event.scale as two fingers move on the screen
                // For other browsers just calculate the scale manually
                let scale;
                if (event.scale) {
                    scale = event.scale;
                } else {
                    const deltaDistance = this.distance(event);
                    scale = deltaDistance / this.start.distance;
                }
                this.zoomScale = Math.min(Math.max(1, scale), 4).toFixed(4);

                const deltaX = ((event.touches[0].pageX + event.touches[1].pageX) / 2 - this.start.x) * 2;
                const deltaY = ((event.touches[0].pageY + event.touches[1].pageY) / 2 - this.start.y) * 2;

                this.transformStyles = `transform: matrix(${this.zoomScale}, 0,0, ${this.zoomScale}, ${deltaX}, ${deltaY});
                                            transform-origin: ${this.start.x}px ${this.start.y}px 0px; touch-action: none;
                                            animation-timing-function: linear;`;
            }
        },
        handleMouseleave() {
            this.zoomScale = 1;
            this.zoomX = 0;
            this.zoomY = 0;
            this.mouseover = false;
            this.transformStyles = false;
            if (this.touchdevice) {
                setTimeout(() => this.$refs.slider?.glide?.update({ touchAngle: 45 }), 100);
            }
        },
        getVideoEmbedLink(image) {
            let url = image?.video_content?.video_url;

            if (!url || !url.length) {
                return null;
            }

            if (url && url.indexOf('https://vimeo.com') > -1) {
                return url.replace('https://vimeo.com', '//player.vimeo.com/video');
            }

            if (url && url.indexOf('youtu.be/') > -1) {
                return url.replace('youtu.be/', 'www.youtube.com/embed/');
            }

            if (url && url.indexOf('/watch?v=') > -1) {
                return url.replace('/watch?v=', '/embed/');
            }

            return url;
        },
        prepareGallery(gallery) {
            if (!gallery) {
                return [];
            }

            return gallery.filter((item) => !item.disabled).sort((itemA, itemB) => itemA.position - itemB.position);
        },
    },
};
